import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import { rolesListRequest, updateRoleRequest } from "../../../store/roles/actions";
import { rolesSelector } from "../../../store/roles/selectors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { FormGroup, FormLabel, StyledError } from "../../../components/StyledErro";
import { AdminFormWrapper } from "../../Admins/Admins.style"; 
import { FormModal } from "../../../components/FormModal/FormModal"; 
import { hideModal } from "../../../store/modal/actions"; 


const RoleEditValidationSchema = Yup.object().shape({
  libelle: Yup.string().required("Le nom du rôle est requis"),
  permissions: Yup.array().min(1, "Au moins une permission est requise"),
});

const RolesEditForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const useRoles = rolesSelector(useSelector((state) => state));

  const initialValues = {
    libelle: useRoles?.detail_roles?.libelle || "",
    permissions: useRoles?.detail_roles?.permissions || [],
  };

  const handleSubmit = async (values) => {
    dispatch(updateRoleRequest(id, values))
      .then(() => {
        toast.success("Rôle mis à jour avec succès!");
      })
      .catch(() => {
        toast.error("Échec de la mise à jour du rôle.");
      });
  };

  useEffect(() => {
    dispatch(rolesListRequest());
  }, [dispatch]);

  const permissionsList = [
    { value: "create-solde", label: "Créer solde" },
    { value: "delete-solde", label: "Supprimer solde" },
    { value: "update-solde", label: "Modifier solde" },
    { value: "view-any-solde", label: "Voir tous les soldes" },
    
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RoleEditValidationSchema}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form className="legafrik-form-parent">
          <FormGroup>
            <FormLabel htmlFor="libelle">Nom du rôle :</FormLabel>
            <Field name="libelle">
              {({ field }) => (
                <Input
                  {...field}
                  className="input-format"
                  id="libelle"
                  name="libelle"
                  type="text"
                  value={values.libelle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Nom du rôle"
                />
              )}
            </Field>
            <ErrorMessage name="libelle" component={StyledError} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Permissions :</FormLabel>
            <div className="permissions-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {permissionsList.map((permission) => (
                <div key={permission.value} style={{ display: 'inline-block', marginRight: '10px' }}>
                  <Field type="checkbox" name="permissions" value={permission.value} />
                  <label htmlFor={permission.value} style={{ marginLeft: '5px' }}>{permission.label}</label>
                </div>
              ))}
            </div>
            <ErrorMessage name="permissions" component={StyledError} />
          </FormGroup>

          <div className="legafrik-form-btn-box">
            <Button type="submit" title="Mettre à jour le rôle" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const RolesEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const useModal = useSelector((state) => state.modal); 
  const useRoles = useSelector((state) => state.roles); 

  const _handleConfirmed = () => {
    const sendData = {
      id,
      libelle: useModal?.data?.libelle,
      permissions: useModal?.data?.permissions,
    };
    dispatch(updateRoleRequest(sendData));
  };

  useEffect(() => {
    dispatch(rolesListRequest());
  }, [dispatch]);

  return (
    <AdminFormWrapper>
      <div className="form-header">
        <h3 className="form-header-title">Modification d'un rôle</h3>
      </div>
      <RolesEditForm />
      {useModal.isOpenConfirmedArticleAdd && (
        <FormModal isControlVisible={true} handleModalClose={() => dispatch(hideModal())}>
          <div className="legafrik-confirm-body">
            <div className="legafrik-confirm-body-content">
              <div className="legafrik-confirm-body-item">
                <span className="legafrik-confirm-body-item-title">Nom du rôle :</span>
                <span className="legafrik-confirm-body-item-info">{useModal?.data?.libelle}</span>
              </div>
              <div className="legafrik-confirm-body-item">
                <span className="legafrik-confirm-body-item-title">Permissions :</span>
                <span className="legafrik-confirm-body-item-info">
                  {useModal?.data?.permissions.join(", ")}
                </span>
              </div>
            </div>
          </div>

          <div className="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5">
            <button
              type="button"
              className="button border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
              onClick={() => dispatch(hideModal())}
            >
              Fermer
            </button>
            <button
              onClick={_handleConfirmed}
              className="button bg-theme-1 text-white"
              disabled={useRoles.update_roles_loading}
            >
              {useRoles.update_roles_loading ? "Envoi en cours ..." : "Confirmer"}
            </button>
          </div>
        </FormModal>
      )}
      <ToastContainer />
    </AdminFormWrapper>
  );
};

export default RolesEdit;
