import * as constants from "./constants";

export const  rolesListRequest =()=> {
    return { type: constants.ROLES_LIST_REQUESTING}
}

export const rolesListSuccess=(payload)=>{
    return { type:constants.ROLES_LIST_SUCCESS, payload }
}

export const rolesListFailed=(error)=>{
    return { type:constants.ROLES_LIST_FAILED, error }
}

export const updateRoleRequest = (id, payload) => {
    return { type: constants.UPDATE_ROLE_REQUEST, id, payload };
};

export const hideModal = () => ({
    type: 'HIDE_MODAL',
});
