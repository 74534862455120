import { put, call, takeEvery } from "redux-saga/effects";
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import * as navigations from "../../utils/navigation";
const _adminsService = new AdminsService();

/**
 *
 * @returns {Generator<*, void, *>}
 */
function* listDemandsSaga(request) {
  // console.log("request", request);
  const { payload } = request;
  let url = `admin/demandes?page=${payload?.page}`;

  if (
    payload?.etat_demande_id !== "" &&
    payload?.etat_demande_id !== undefined
  ) {
    url += `&etat_demande_id=${payload?.etat_demande_id}`;
  }

  if (payload?.pays_id !== "" && payload?.pays_id !== null) {
    url += `&pays_id=${payload?.pays_id}`;
  }

  if (payload?.denomination !== "") {
    url += `&denomination=${payload?.denomination}`;
  }

  // if (payload?.numero_demande !== "") {
  //   url += `&numero_demande=${payload?.numero_demande}`;
  // }

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.demandsListSuccess(response?.data));
  } catch (error) {
    yield put(constants.demandsListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* listDemandsPaymentsSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/demandes/${payload}/paiements`;
  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.demandsPaymentListSuccess(response?.data?.paiements));
  } catch (error) {
    yield put(constants.demandsPaymentListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* listPiecesPaymentsSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/demandes/${payload}/pieces`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.demandsPieceListSuccess(response?.data?.pieces));
  } catch (error) {
    yield put(constants.demandsPieceListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* listDocumentsPaymentsSaga(loginPayload) {
  const { payload } = loginPayload;
  //`admin/demandes/${id}/documents/${type_demande_id}/etape-traitement`

  const url = `admin/demandes/${payload.demande_id}/documents/${payload.etape_traitement_id}/etape-traitement`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.demandsDocumentsListSuccess(response?.data?.documents));
  } catch (error) {
    yield put(constants.demandsDocumentsListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* listObservationsPaymentsSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/demandes/${payload}/observations`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(
      constants.demandsObservationsListSuccess(response?.data?.observations)
    );
  } catch (error) {
    yield put(constants.demandsObservationsListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsDeletePieceSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/pieces/${payload.id}`;
  try {
    const response = yield call(_adminsService.delete, url);
    yield put(constants.demandsDeletePieceSuccess(response?.data));
    yield call(toast.success, "Le document a été supprimé avec succès.");
    yield put(constants.demandsPieceListRequest(payload?.id_demand));
    //window.location.reload();
  } catch (error) {
    yield put(constants.demandsDeletePieceFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsAddDocumentSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/documents`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.demandsAddDocumentSuccess(response?.data?.demande));
    yield call(toast.success, "Le document a été ajouté avec succès.");
    yield put(
      constants.demandsDocumentsListRequest({
        demande_id: payload.demande_id,
        etape_traitement_id: payload.etape_traitement_id,
      })
    );
    yield put(constants.demandsObservationsListRequest(payload.demande_id));
  } catch (error) {
    yield put(constants.demandsAddDocumentFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsEditDocumentSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/documents/${payload.id}`;
  const send_data = {
    type_document_id: payload.type_document_id,
    etat_document_id: payload.etat_document_id,
    demande_id: payload.demande_id,
    etape_traitement_id: payload.etape_traitement_id,
    document_url: payload.document_url,
    champs_document: payload.champs_document,
  };

  try {
    const response = yield call(_adminsService.put, url, send_data);
    yield put(constants.demandsEditDocumentSuccess(response?.data?.demande));
    yield call(toast.success, "Le document a été modifié avec succès.");
    yield put(
      constants.demandsDocumentsListRequest({
        demande_id: payload.demande_id,
        etape_traitement_id: payload.etape_traitement_id,
      })
    );
    yield put(constants.demandsObservationsListRequest(payload.demande_id));
  } catch (error) {
    yield put(constants.demandsEditDocumentFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsEditQuestionnaireSaga(loginPayload) {
  const { payload, history } = loginPayload;
  const url = `admin/demandes/${payload.id}/champs-questionnaire`;
  const send_data = {
    champs_questionnaire: payload.champs_questionnaire,
  };

  try {
    const response = yield call(_adminsService.put, url, send_data);
    yield put(
      constants.demandsEditQuestionnaireSuccess(response?.data?.demande)
    );
    yield call(
      toast.success,
      "La modifications du questionnaire a été modifié avec succès."
    );
    yield call(history, `/document-processing/${payload.id}/step`);
  } catch (error) {
    yield put(constants.demandsEditQuestionnaireFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsDeleteDocumentSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/documents/${payload.id}`;

  try {
    const response = yield call(_adminsService.delete, url);
    yield put(constants.demandsDeleteDocumentSuccess(response?.data?.demande));
    // yield put(constants.demandsPieceListRequest(payload?.demande_id))
    yield call(toast.success, "Le document a été supprimé avec succès.");
    yield put(
      constants.demandsDocumentsListRequest({
        demande_id: payload?.demande_id,
        etape_traitement_id: payload?.etape_traitement_id,
      })
    );
    yield put(constants.demandsObservationsListRequest(payload?.demande_id));
  } catch (error) {
    yield put(constants.demandsDeleteDocumentFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsDetailSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/demandes/${payload}`;
  try {
    const response = yield call(_adminsService.get, url);
    yield put(constants.demandsDetailSuccess(response?.data?.demande));
  } catch (error) {
    yield put(constants.demandsDetailFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* demandsStatusEtatTraitementListSaga(loginPayload) {
  const { payload } = loginPayload;
  //   admin/demandes/${id}/documents/${type_demande_id}/etape-traitement`
  const url = `admin/demandes/${payload?.demande_id}/etape-traitements/${payload?.etape_traitement_id}/status-etat-traitement`;
  try {
    const response = yield call(_adminsService.get, url);
    yield put(
      constants.demandsStatusEtatTraitementListSuccess(
        response?.data?.status_etat_traitement
      )
    );
  } catch (error) {
    if (error.response.data.message === "status etat traitement exists") {
      yield put(constants.demandsStatusEtatTraitementListFailed(error));
    } else {
      yield put(constants.demandsStatusEtatTraitementListFailed(error));
      yield call(
        toast.error,
        "Un problème est survenu veuillez contacter le service client."
      );
    }
  }
}

function* demandsDocumentsComplementaireSaga(loginPayload) {
  const { payload } = loginPayload;
  const url = `admin/documents/sup`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(
      constants.demandsDocumentsComplementaireSuccess(
        response?.data?.observations
      )
    );
  } catch (error) {
    yield put(constants.demandsDocumentsComplementaireFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}
function* listDemandsPaysSaga(loginPayload) {
  const { payload } = loginPayload;
  // console.log("loginPayload", loginPayload);
  let url = `admin/demandes?page=${payload?.page}`;

  if (payload.etat_demande_id) {
    url += `&etat_demande_id=${payload.etat_demande_id}`;
  }

  if (payload.pays_id) {
    url += `&pays_id=${payload?.pays_id}`;
  }

  if (payload.denomination) {
    url += `&denomination=${payload.denomination}`;
  }

  if (payload.numero_demande) {
    url += `&numero_demande=${payload.numero_demande}`;
  }

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.demandsPaysListSuccess(response?.data));
  } catch (error) {
    yield put(constants.demandsPaysListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}
function* listDemandsTypeSaga(request) {
  const { payload } = request;

  try {
    const response = yield call(
      _adminsService.geList,
      `pays/${payload}/type-demandes`
    );
    yield put(constants.demandsTypeListSuccess(response?.data?.type_demandes));
  } catch (error) {
    yield put(constants.demandsTypeListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}
function* listCreateDemandsSaga(createDemandsPayload) {
  const { payload, history } = createDemandsPayload;
  const url = `user/demandes`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.createDemandsSuccess(response?.data));
  } catch (error) {
    yield put(constants.createDemandsFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}
function* listCreateHubspotLeadsSaga(createHubspotLeadsPayload) {
  const { payload } = createHubspotLeadsPayload;
  const url = `hubspot/leads`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.createHubspotLeadsSuccess(response?.data));
  } catch (error) {
    yield put(constants.createHubspotLeadsFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* listDemandsPaymentSaga(demandsPaymentPayload) {
  const { payload } = demandsPaymentPayload;
  const url = `user/paiements`;
  try {
    const response = yield call(_adminsService.postOffline, url, payload);
    yield put(constants.demandsPaymentSuccess(response?.data));
  } catch (error) {
    yield put(constants.demandsPaymentFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* verificationUserSaga(verificationUserPayload) {
  console.log(verificationUserPayload);

  const { payload, create_payload, history } = verificationUserPayload;
  const url = `user/exist`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    const send_data = {
      pays_id: create_payload?.pays_id,
      champs_demande: create_payload?.champs_demande,
      montant_total: create_payload?.montant_total,
      type_demande_id: create_payload?.type_demande_id,
      dossier_id: response?.data?.data.dossier_id,
    };

    yield put(constants.createDemandsRequest(send_data, history));
    yield put(constants.verificationUserSuccess(response?.data?.data));
    yield call(history, navigations.DEMANDS_LIST);
    yield call(toast.success, "La demande a été effectuée avec succès.");
  } catch (error) {
    yield put(constants.verificationUserFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

export default function* demandsSaga() {
  yield takeEvery(Actions.DEMANDS_LIST_REQUESTING, listDemandsSaga);
  yield takeEvery(
    Actions.DEMANDS_PAYMENTS_LIST_REQUESTING,
    listDemandsPaymentsSaga
  );
  yield takeEvery(Actions.DEMANDS_DETAIL_REQUESTING, demandsDetailSaga);
  yield takeEvery(
    Actions.DEMANDS_PIECES_LIST_REQUESTING,
    listPiecesPaymentsSaga
  );
  yield takeEvery(
    Actions.DEMANDS_DOCUMENTS_LIST_REQUESTING,
    listDocumentsPaymentsSaga
  );
  yield takeEvery(
    Actions.DEMANDS_OBSERVATIONS_LIST_REQUESTING,
    listObservationsPaymentsSaga
  );
  yield takeEvery(
    Actions.DEMANDS_DELETE_PIECE_REQUESTING,
    demandsDeletePieceSaga
  );
  yield takeEvery(
    Actions.DEMANDS_ADD_DOCUMENT_REQUESTING,
    demandsAddDocumentSaga
  );
  yield takeEvery(
    Actions.DEMANDS_EDIT_DOCUMENT_REQUESTING,
    demandsEditDocumentSaga
  );
  yield takeEvery(
    Actions.DEMANDS_DELETE_DOCUMENT_REQUESTING,
    demandsDeleteDocumentSaga
  );
  yield takeEvery(
    Actions.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_REQUESTING,
    demandsStatusEtatTraitementListSaga
  );
  yield takeEvery(
    Actions.DEMANDS_DOCUMENT_COMPLEMENTAIRE_REQUESTING,
    demandsDocumentsComplementaireSaga
  );
  yield takeEvery(Actions.DEMANDS_PAYS_LIST_REQUESTING, listDemandsPaysSaga);
  yield takeEvery(
    Actions.DEMANDS_EDIT_QUESTIONNAIRE_REQUESTING,
    demandsEditQuestionnaireSaga
  );
  yield takeEvery(Actions.DEMANDS_TYPE_LIST_REQUESTING, listDemandsTypeSaga);

  yield takeEvery(Actions.CREATE_DEMANDS_REQUESTING, listCreateDemandsSaga);
  yield takeEvery(
    Actions.CREATE_HUBSPOT_LEADS_REQUESTING,
    listCreateHubspotLeadsSaga
  );
  yield takeEvery(Actions.DEMANDS_PAYMENT_REQUESTING, listDemandsPaymentSaga);
  yield takeEvery(Actions.DEMANDS_PAYMENT_REQUESTING, listDemandsPaymentSaga);
  yield takeEvery(Actions.VERIFICATION_USER_REQUESTING, verificationUserSaga);
}
