export const DASHBOARD = "/";
export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const NOTFOUND = "/notfound";
export const CHANGE_PASSWORD = "/auth/change-password/:token/account";
export const DEMANDS_LIST = "/demands/list";
export const ADMINS_LIST = "/admins/:type/list";
export const CUSTOMERS_LIST = "/customers-list";
export const ADMIN_NOTIFICATION = "/admin-notification";
export const ADMIN_SETTINGS = "/admin-settings";
export const ADMINS_ADD = "/add-admins";
export const ADMINS_EDIT = "/admins/:id/edit";
export const ADMINS_DETAIL = "/admins/:id/detail";
export const LEADS_LIST_TYPE = "/leads/:type_lead/list";
export const DEMANDS_PAYMENT = "/demands-payment/info";
export const DEMANDS_CREATE_PAYMENT = "/demands-payment/create/:id_demand";
export const DETAIL_LEADS = "/leads/:id/detail";
export const EDIT_LEADS = "/leads/:id/edit";
export const DEMANDS_DETAIL = "/demands/:id/detail";
export const DOCUMENT_PROCESSING_STEP = "/document-processing/:id/step";
export const EDIT_QUESTIONNAIRE = "/document-processing/:id/edit-quesionnaire";
export const ROLES_LIST = "/roles-list";
export const ADMIN_CHANGE_PASSWORD = "/admin/change-password";
export const ARTICLE_LIST = "/articles-list";
export const ARTICLE_ADD = "/articles/add";
export const ARTICLE_EDIT = "/articles/:id/edit";
export const ARTICLE_DETAIL = "/articles/:id/detail";
export const RECOUVREMENT_LIST = "/recouvrements-list";
export const DEMANDE_CREATE = "/demandes/:type/:id";
export const DEMANDE_TYPE = "/demandes/type";

/* POS */
export const DEMANDE_ORGANISATION = "/organisations";
export const ORGANISATION_CREATE = "/organisations/create";
export const LISTE_AGENCE = "/liste_agences/:id";
export const LISTE_UTILISATEUR = "/utilisateurs/:id";
export const CLIENT_EDIT = "/client/:id/edit";
export const LOGS = "/logs";
export const TRANSACTIONS = "/transaction";
export const VENTES = "/vente";
export const CLE_API = "/apikey/:id";
export const ROLES_EDIT = "/roles/edit/:id";



