import { put, call, takeEvery } from 'redux-saga/effects';
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";

const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listRolesSaga(loginPayload) {
    const url = 'admin/roles';
    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.rolesListSuccess(response?.data?.roles));
    } catch (error) {
        yield put(constants.rolesListFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.");
    }
}

/**
 * @param updatePayload
 */
function* updateRoleSaga({ id, payload }) {
    const url = `admin/roles/${id}`;
    try {
        const response = yield call(_adminsService.put, url, payload);
        yield put({ type: Actions.UPDATE_ROLE_SUCCESS, payload: response.data });
        yield call(toast.success, "Rôle mis à jour avec succès!");
    } catch (error) {
        yield put({ type: Actions.UPDATE_ROLE_FAILED, error });
        yield call(toast.error, "Échec de la mise à jour du rôle.");
    }
}

export default function* rolesSaga() {
    yield takeEvery(Actions.ROLES_LIST_REQUESTING, listRolesSaga);
    yield takeEvery(Actions.UPDATE_ROLE_REQUEST, updateRoleSaga);
}
